import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
import { ToolsIcon } from '@smallstep/step-ui';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`The DevOps practitioners guide`}</h2>
    <p>{`Zero Trust or BeyondProd approaches require authenticated and encrypted communications everywhere.
TLS is the cryptographic protocol that powers encryption for all your technologies.
For TLS, you need certificates.
This series of practitioner's tutorials provide instructions for automating TLS certificates for popular technologies.
Get started quickly with the `}<strong parentName="p">{`try it`}</strong>{` path and learn about TLS with your specific technology.
Then, when ready, follow the `}<strong parentName="p">{`operationalize it`}</strong>{` path to automate deployments and certificate management powering server-side encryption.
The tutorials include configuration options for Linux, Docker, and Kubernetes environments using the ACME protocol, systemd timers, and other modern techniques for certificate management.`}</p>
    <h2>{`Available Tutorials`}</h2>
    <h3><a parentName="h3" {...{
        "href": "https:/prof.infra.smallstep.com/practical-zero-trust/redis-tls"
      }}>{`Redis TLS >`}</a></h3>
    <h3><a parentName="h3" {...{
        "href": "https:/prof.infra.smallstep.com/practical-zero-trust/mongodb-tls"
      }}>{`MongoDB TLS >`}</a></h3>
    <h3><a parentName="h3" {...{
        "href": "https:/prof.infra.smallstep.com/practical-zero-trust/kubernetes-ingress-tls"
      }}>{`Kubernetes Ingress TLS >`}</a></h3>
    <h3><a parentName="h3" {...{
        "href": "https:/prof.infra.smallstep.com/practical-zero-trust/nginx-tls"
      }}>{`nginx TLS >`}</a></h3>
    <h3><a parentName="h3" {...{
        "href": "https:/prof.infra.smallstep.com/practical-zero-trust/mysql-tls"
      }}>{`MySQL TLS >`}</a></h3>
    <h3><a parentName="h3" {...{
        "href": "https:/prof.infra.smallstep.com/practical-zero-trust/postgresql-tls"
      }}>{`PostgreSQL TLS >`}</a></h3>
    <h3><a parentName="h3" {...{
        "href": "https:/prof.infra.smallstep.com/practical-zero-trust/istio-tls"
      }}>{`Istio TLS >`}</a></h3>
    <h3><a parentName="h3" {...{
        "href": "https:/prof.infra.smallstep.com/practical-zero-trust/go-grpc-tls"
      }}>{`Go gRPC TLS >`}</a></h3>
    <h2>{`Looking For Something Specific?`}</h2>
    <p>{`We are continually updating our tutorial library and would love to hear what you want to learn next. Visit `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/certificates/discussions"
      }}>{`GitHub Discussions`}</a>{` and let us know.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      